import type { MainSolutionOptionsObject } from "../../translation-keys/main-solution-option-keys.js";

const MainSolutions: MainSolutionOptionsObject = {
  "Quality": "Quality",
  "Manufacturing": "Manufacturing",
  "Logbooks": "Logbooks",
  "Clinical": "Clinical",
  "Product Development": "Product Development",
  "Regulatory": "Regulatory",
  "Supplier": "Supplier",
  "Post-Market": "Post-Market"
};

export default MainSolutions;